<template>
  <b-card-code
    title="Report Pengujian dan Kalibrasi"
  >
  <validation-observer
      ref="actionForm"
    >
    <b-form 
      @submit.prevent="created"
    >
      <b-row>
        <b-col 
          lg="12"
          md="12"
        >        
          <div role="group">
            <label for="input-live">{{ $t('Form.Action.Status') }} {{instansi}}</label>
                <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="name"
                    rules="required"
                >
                    <b-form-group>
                      <v-select
                        v-model="status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="statusOptions"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </div>
        </b-col>
        <b-col 
          lg="12"
          md="12"
        >        
          <div role="group">
            <label for="input-live">{{ $t('Form.Action.Date') }} </label>
                <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="name"
                    rules="required"
                >
                  <flat-pickr
                    v-model="date"
                    class="form-control"
                    :config="{ dateFormat: 'd-m-Y'}"
                    placeholder="DD-MM-YYYY"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </div>
        </b-col>
        <b-col
          cols="12"
        >
          <div role="group">
              <label for="input-live">{{ $t('Form.Action.Description') }}</label>
              <b-form-textarea
                  id="notes"
                  v-model="description"
                  rows="2"
                  />
          </div>
        </b-col>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            {{ $t('Submit') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            {{ $t('Reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code'
import { required } from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadioGroup, BFormTextarea, BCardText
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  props: {
    product: {
      type: String,
    },
    instansi: {
      type: String,
      required: true
    }
  },
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    flatPickr,
    vSelect
  },
  directives: {
    Ripple,
  },
  data: () => ({
    status: '',
    date: '',
    description: '',
    required,
    statusOptions:  [{ title: 'Laik Pakai', id:1 }, { title: 'Laik Pakai Kuning', id:2 }, { title: 'Tidak Laik Pakai', id:3 }],
  }),
  methods: {
    created() { 
      this.$refs.actionForm.validate().then(success => {
        if (success) {
          moduleApi.createAction({
            date: this.date,
            description: this.description,
            status: this.status.id,
            id_product: this.$route.params.id
          })
            .then(response => {
              console.log(this.instansi)
              this.$router.replace({name: 'instansi'})
            })
            .catch(error => {
              this.$refs.warehouseForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
